<template>
    <div class="contener">
        <!-- <button class="btn-rond">
            <img src="../assets/moon.svg" alt="O">
        </button> -->
        <div class="barre" :class="{ 'focus': isInputFocused }">
            <form @submit.prevent="search">
                <input type="text" v-model="searchQuery" @focus="inputFocused" @blur="inputBlurred" placeholder="Search">
                <button type="submit"><img src="../assets/search.svg" alt="Rechercher" width="20px"></button>
            </form>
        </div>
        <!-- <button class="btn-rond" @click="afficherFormulaire">
            <img src="../assets/plus.svg" alt="+">
        </button> -->
    </div>
    <!-- <button class="fond" v-if="afficherFormulaireFav" @cacherFormulaire="cacherFormulaire" @click="cacherFormulaire"></button>
    <FormCreerFav class="form-pop-up" v-if="afficherFormulaireFav" @cacherFormulaire="cacherFormulaire" @createNewFolder="createNewFolder" /> -->
</template>

<script>
// import FormCreerFav from './Formulaire/Form-creer-fav.vue'

export default {
    components: {
        // FormCreerFav
    },
  data() {
    return {
      searchQuery: '',
      isInputFocused: false,
      afficherFormulaireFav: false
    };
  },
  methods: {
    search() {
      // Effectuer la recherche sur Google
      window.location.href = `https://www.google.com/search?q=${encodeURIComponent(this.searchQuery)}`;
    },
    inputFocused() {
      this.isInputFocused = true;
    },
    inputBlurred() {
      this.isInputFocused = false;
    },
    afficherFormulaire() {
      this.afficherFormulaireFav = true; // Affiche le formulaire de création de favori lorsque le bouton est cliqué
    },
    cacherFormulaire() {
      this.afficherFormulaireFav = false; // Masque le formulaire lorsque l'utilisateur a terminé la création du favori
    },
    createNewFolder() {
        this.$emit('createNewFolder'); // Émet l'événement createNewFolder lors de la création d'un nouveau dossier
    },
  }

}
</script>

<style scoped>
    .contener{
        display: flex;
        justify-content: center;
    }
    .barre{
        background: #ffffffb0;
        width: 40%;
        height: 50px;
        border-radius: 50px;
        margin: 0 50px;
        transition: width 0.2s ease, height 0.2s ease, margin 0.2s ease;
    }
    .barre:hover{
        width: calc(40% + 6px);
        height: calc(50px + 6px);
        margin: -3px 47px;
    }
    .barre.focus{
        width: calc(40% + 6px);
        height: calc(50px + 6px);
        margin: -3px 47px;
        background: #ffffffdd;
    }
    form{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        padding: 0px 20px;
    }
    input[type="text"]{
        border: unset;
        height: 80%;
        width: 100%;
        font-size: medium;
        background: unset;
        color: #707070;
    }
    input:focus{
        outline: none;
    }
    button{
        background: unset;
        border: unset;
        cursor: pointer;
    }
    button[type="submit"]{
        display: flex;
        align-items: center;
    }
    .btn-rond{
        width: 50px;
        height: 50px;
        border-radius: 50px;
        background: #ffffffb0;
        transition: width 0.2s ease, height 0.2s ease, margin 0.2s ease;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .btn-rond:hover{
        width: 56px;
        height: 56px;
        margin: -3px -3px;
    }
    .btn-rond img{
        width: 50%;
        height: 50%
    }
    .fond{
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: #00000060;
        backdrop-filter: blur(5px);
        z-index: 5;
    }
    .form-pop-up{
        position: absolute;
        z-index: 7;
    }
</style>