<template>
  <SearchGoogle @createNewFolder="createNewFolder" />
  <ListeFav/>
  

</template>

<script>
import ListeFav from './components/Galerie.vue'
import SearchGoogle from './components/SearchGoogle.vue'

export default {
  name: 'App',
  components: {
    ListeFav,
    SearchGoogle
  },
  data() {
    return {
      folders: [] // Initialisez la variable folders avec un tableau vide
    };
  },
  mounted() {
    // Initialisez la base de données IndexedDB
    this.initIndexedDB();
  },
  methods: {
    async initIndexedDB() {
      // Ouvrez une connexion avec la base de données IndexedDB
      const db = await this.openIndexedDB();

      // Créez un objet de stockage pour les dossiers si ce n'est pas déjà fait
      if (!db.objectStoreNames.contains('folders')) {
        db.createObjectStore('folders', { keyPath: 'id', autoIncrement: true });
      }
    },
    async openIndexedDB() {
      return new Promise((resolve, reject) => {
        const request = window.indexedDB.open('favorisDB', 1);
        request.onerror = () => {
          console.error('Erreur lors de l\'ouverture de la base de données IndexedDB');
          reject();
        };
        request.onsuccess = () => {
          resolve(request.result);
        };
        request.onupgradeneeded = (event) => {
          const db = event.target.result;
          db.createObjectStore('folders', { keyPath: 'id', autoIncrement: true });
        };
      });
    },
    async createNewFolder() {
      const nouveauDossier = prompt('Entrez le nom du nouveau dossier :');
      if (nouveauDossier) {
        // Enregistrez le nouveau dossier dans IndexedDB
        const db = await this.openIndexedDB();
        const transaction = db.transaction('folders', 'readwrite');
        const store = transaction.objectStore('folders');
        const newFolder = { name: nouveauDossier };
        const request = store.add(newFolder);
        request.onsuccess = () => {
          // Mettez à jour la liste des dossiers dans le composant avec le nouveau dossier enregistré
          this.folders.push({ ...newFolder, id: request.result });
        };
      }
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');
*{
  font-family: "Lexend", sans-serif;
}
#app {
  font-family: "Lexend", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #FFF;
  margin-top: 60px;
}
body{
  background: #4decdc;
  background: linear-gradient(90deg, #ffbe55 0%, #ff6ae4 100%);
}
</style>
