<template>
    <div></div>
</template>
  
  <script>
  export default {
    name: 'Liste-Fav',
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>

  </style>
  